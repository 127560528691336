<template>
  <q-form ref="editForm">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 eduCard">
      <!-- 기본정보 -->
      <c-card class="cardClassDetailForm" title="교육계획 기본정보">
        <template slot="card-button">
          <!-- 저장 -->
          <c-btn
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveEducation"
            @btnCallback="saveCallback" 
          />
        </template>
        <template slot="card-detail">
          <div class="col-3">
            <!-- 교육명 -->
            <c-label-text title="LBL0002929" :value="educationInfo.educationName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 교육과정 -->
            <c-label-text title="LBL0002930" :value="educationInfo.educationCourseName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 교육종류1 -->
            <c-label-text title="LBL0002933" :value="educationInfo.educationKindCdLargeName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 교육종류2 -->
            <c-label-text title="LBL0002934" :value="educationInfo.educationKindCdSmallName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 교육구분 -->
            <c-label-text title="LBL0002935" :value="educationInfo.educationTypeName"></c-label-text>
          </div>
          <!-- <div class="col-3">
            <c-label-text title="교육방법" :value="educationInfo.educationMethodName"></c-label-text>
          </div> -->
          <div class="col-3">
            <!-- 사업장 -->
            <c-label-text title="LBLPLANT" :value="educationInfo.plantName"></c-label-text>
          </div>
          <div class="col-3">
            <!-- 주관부서 -->
            <c-label-text title="LBL0002854" :value="educationInfo.departmentDeptName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 eduCard2">
      <!-- 교육평가 및 경비정보 -->
      <c-card title="LBL0002904" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <!-- 교육총평 -->
            <c-textarea
              :disabled="disabled"
              :rows="3"
              :editable="editable" 
              name="educationOverview"
              label="교육총평" 
              v-model="educationInfo.educationOverview" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <!-- 비고 -->
            <c-textarea
              :editable="editable"
              :disabled="disabled"
              :rows="3"
              label="비고"
              name="remark"
              v-model="educationInfo.remark">
            </c-textarea>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <!-- 원 -->
            <!-- 집행예산 -->
            <c-text
              :suffix="$label('LBL0002813')"
              type="number"
              :editable="editable"
              :disabled="disabled"
              label="LBL0002908"
              name="executionBudget"
              v-model="educationInfo.executionBudget">
            </c-text>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <!-- 원 -->
            <!-- 예산잔액 -->
            <c-text
              :suffix="$label('LBL0002813')"
              type="number"
              :editable="editable"
              :disabled="true"
              label="LBL0002910"
              name="budgetPlan"
              v-model="budgetPlan">
            </c-text>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
            <!-- 교육평가 -->
            <c-select
              :editable="editable"
              :disabled="disabled"
              type="edit"
              codeGroupCd="EDU_EVAL_CD"
              itemText="codeName"
              itemValue="code"
              name="evaluationEvalCd"
              label="LBL0002911"
              v-model="educationInfo.evaluationEvalCd"
            ></c-select>
          </div>
        </template>
      </c-card>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 평가자 목록 -->
      <c-table
        ref="table"
        title="LBL0002909"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="educationInfo.eduEvalUserList"
        :filtering="false"
        :checkClickFlag="false"
        :columnSetting="false"
        :usePaging="false"
        :merge="grid.merge"
        :isExcelDown="false"
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="evalUserId"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable && !disabled" label="LBLADD" :showLoading="false"  icon="add" @btnClicked="addEvalUser" />
            <!-- 제외 -->
            <c-btn v-if="editable && !disabled && educationInfo.eduEvalUserList.length > 0" label="LBLEXCEPT" :showLoading="false" icon="remove" @btnClicked="removeEvalUser" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    </div>
  <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-result-info',
    components: {
  },

  props: {
    param: {
      type: Object,
      default: () => ({
        eduEducationId: '',
      }),
    },
    educationInfo: {
      type: Object,
      default: () => ({
        eduEducationId: '',
        educationName: '',
        eduCourseId: '',
        plantCd: '',
        educationCourseName: '',
        educationKindCdLarge: null,
        educationKindCdSmall: null,
        educationTypeCd: null,
        departmentDeptCd: '',
        departmentDeptName: '',
        executionBudget: '',
        estimatedEducationExpenses: '',
        educationMethodName: '',
        educationMethodCd: null,
        educationOverview: '',
        evaluationEvalCd: null,
        documentStatusCd: '',
        qrCodeRoute: '',

        regUserId: '',
        chgUserId: '',
        eduSubjectList: [], // 과정별 교육과목
        eduAttendeeList: [],
        eduEvalUserList: [],
        deleteEduEvalUserList: [],
      }),
    },
    planUpdateBtnData: {
      title: 'LBLPLAN',
      flag: false,
      research: '',
      planResearch: '',
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          {
            name: 'evalStandard',
            field: 'evalStandard',
            style: 'width:40%',
            type: 'text',
            // 평가기준
            label: 'LBL0002912',
            align: 'left',
            sortable: false,
          },
          {
            name: 'evalUserName',
            field: 'evalUserName',
            style: 'width:20%',
            // 평가자
            label: 'LBL0002913',
            align: 'center',
            sortable: false,
          },
          {
            name: 'evalResult',
            field: 'evalResult',
            style: 'width:40%',
            type: 'text',
            label: '평가결과',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      title: '',
      selectDate: '',
      isOpen: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      budgetPlan: 0,
      saveUrl: 'transactionConfig.sop.edu.result.update.url',
      saveType: 'PUT',
      isSave: false,
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
      completeUrl: '',
    };
  },
  computed: {
    disabled() {
      return Boolean(this.educationInfo.documentStatusCd) && this.educationInfo.documentStatusCd === 'ESC000010'
    }
  },
  /* eslint-disable no-unused-vars */
  watch: {
    'educationInfo.executionBudget'() {
      this.budgetPlan = Number(this.educationInfo.estimatedEducationExpenses) - Number(this.educationInfo.executionBudget)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getUrl = selectConfig.sop.edu.result.get.url;
      this.updateUrl = transactionConfig.sop.edu.result.update.url;
      this.completeUrl = transactionConfig.sop.edu.result.complete.url;
    },
    saveEducation() {
      this.saveUrl = this.updateUrl;
      this.saveType = 'PUT';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.educationInfo.chgUserId = this.$store.getters.user.userId
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    addEvalUser() {
      this.popupOptions.title = "LBL0002915"; // 교육평가자 검색
      this.popupOptions.param = {
        type: 'multiple',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/user/userPop.vue'}`)
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.width = '70%';
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
            let index = this.$_.findIndex(this.educationInfo.eduEvalUserList, {
            evalUserId: _item.userId,
          });
          if (index === -1) {
            this.educationInfo.eduEvalUserList.splice(0, 0, {
              eduEducationId: this.param.eduEducationId,
              evalUserId: _item.userId,
              evalUserName: _item.userName,
              evalResult: '',
              evalStandard: '',
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              editFlag: 'C'
            })
          }
        })
      }
    },  
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.eduEducationId = result.data;
      this.$emit('setData', result.data)
    },
    removeEvalUser() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "LBLGUIDE", // 안내
          message: "MSGNOITEMS", // 선택된 항목이 없습니다.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.educationInfo.deleteEduEvalUserList) {
            this.educationInfo.deleteEduEvalUserList = [];
          }
          if (
            this.$_.findIndex(this.educationInfo.deleteEduEvalUserList, {
              evalUserId: item.evalUserId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.educationInfo.deleteEduEvalUserList.push(item);
          }
          this.educationInfo.eduEvalUserList = this.$_.reject(
            this.educationInfo.eduEvalUserList,
            item
          );
        });
      }
    },
  }
};
</script>
<style lang="sass">
  .eduCard 
    .customCardbody
      margin: -10px 0 20px 0 !important
  .eduCard2 
    .customCardbody
      padding: 20px 15px 0px 15px !important
      margin-bottom: 0px !important
</style>